import Vue from 'vue'
import Router from 'vue-router'

const routerOptions = [

    // ================================================ Root =======================================================

    // ================================================ Profile =======================================================
    // { path: '/', name: 'MainDashboard', componentPath: 'HelloWorld' },
    // { path: '/', name: 'ManagerDashboard', componentPath: 'Expense/Dashboards/ManagerExpenseDashboard' },
    // { path: '/', name: 'Dashboard', componentPath: 'HelloWorld' },
    { path: '/', name: 'HomeDashboard', componentPath: 'Dashboard/HomeDashboard' },
    // { path: '/dashboard', name: 'Dashboard', componentPath: 'HelloWorld' },

    // ==============  Employee Info ====================//
    // Employee
    { path: "/employee_info/list", name: "Employee Info List", componentPath: 'Employee/EmployeeListGridView'},
    { path: "/employee_info/payrolls/list", name: "Payrolls List", componentPath: 'Employee/PayrollListView'},
    // { path: "/payslip/", name: "Payslip", componentPath: 'Employee/PayslipGenerate'},
    // { path: "/payslip/edit/:id", name: "Payslip", componentPath: 'Employee/PayslipGenerate'},
    { path: "/payslip/view/:id", name: "Payslip", componentPath: 'Employee/PayslipGenerate'},
    { path: "/employee_info/timesheet/list", name: "Timesheet List", componentPath: 'Employee/TimeSheetListView'},
    { path: "/employee_info/create", name: "Employee Create", componentPath: 'Employee/CreateEditEmployee'},
    { path: "/employee_info/edit/:id/:my_profile/:is_draft", name: "Employee Edit", componentPath: 'Employee/CreateEditEmployee'},
    { path: "/employee_info/view/:id/:my_profile/:is_draft", name: "Employee View", componentPath: 'Employee/CreateEditEmployee'},
    { path: "/employee_info/myshift_details", name: "Myshift Details", componentPath: 'Employee/MyShiftDetails'},
    // Payroll
    { path: "/employee_info/payroll", name: "Employee Payroll", componentPath: 'Home'},
    // Asset Details
    { path: "/employee_info/asset_details", name: "Employee Asset Details", componentPath: 'Home'},
    // Attendance List
    { path: "/employee_info/attendance_list", name: "Employee Attendance", componentPath: 'Employee/EmployeeAttendanceListView'},
    { path: "/employee_info/hr_attendance/:id", name: "HR Attendance", componentPath: 'Employee/attendanceListView'},
    //Leave List
    // { path: "/employee_info/leave_list", name: "Employee Leave", componentPath: 'Employee/Leave/leaveListView'},
    { path: "/employee_info/leave_list", name: "Employee Leave Split View", componentPath: 'Employee/Leave/LeaveSplitView'},
    { path: "/employee_info/leave_log/:leave_type", name: "Employee Leave log", componentPath: 'Employee/Leave/LeaveLogSplitView'},
    { path: "/employee_info/overtime_log/:ot_type", name: "Overtime Request Log", componentPath: 'Employee/Overtime/OvertimeSplitView'},
    { path: "/employee_info/employee_leave_list", name: "Employee Leave List", componentPath: 'Employee/Leave/EmployeeLeaveListView'},
    { path: "/employee_info/view_leave", name: "Employee Leave", componentPath: 'Employee/Leave/ViewLeave'},
    // { path: "/employee_info/hr_attendance/:id", name: "HR Attendance", componentPath: 'Employee/attendanceListView'},
    // Assets List
    { path: "/employee_info/assets_list", name: "Employee Assets", componentPath: 'Employee/AssetListView'},
    
    // Email Redirection
    { path: "/employee_info/overtime_log_details/:comp_id/:user_id/:id", name: "Employee Overtime log Details" , componentPath: 'Employee/ViewSwitchCompany'},
    { path: "/employee_info/leave_request_details/:comp_id/:user_id/:id", name: "Employee Leave log Details", componentPath: 'Employee/ViewSwitchCompany'},

    // ==============  Job Portal ====================//

    { path: "/job_portal", name: "Job Portal", componentPath: 'HelloWorld'},

    // ==============  Policies ====================//

    { path: "/policies", name: "Policies", componentPath: 'HelloWorld'},

    // ==============  Settings ====================//

    { path: "/settings", name: "Settings", componentPath: 'Settings/Settings'},
    { path: "/settings/view_holidays_data", name: "Settings", componentPath: 'Employee/ViewEmployeeHolidays'},

    // ==============  Login and Verification ====================//

    { path: "/login", name: "Login", componentPath: 'Login/Login'},

    // { path: "/user/verify", name: "VerifyUser", componentPath: 'VerifyUser' },
    { path: "/verifyOtp", name: "OtpVerify", componentPath: 'OtpVerify' },

    // Access Denied
    // { path: '/subscription', name: 'AccessDenied', componentPath: 'AccessDenied'},





    // ***** Use other routes above this line only if you have a specific need *****


    // ================================================= Page Not Found ==============================================================
    {path:'/pagenotfound', name:'PageNotFound', componentPath: 'PageNotFound'},

    {path:'*', name:'PageNotFoundError', redirect: '/pagenotfound'}
]

const routes = routerOptions.map(route => {
    return {
        ...route,
        component: () => import(`@/components/${route.componentPath}.vue`)
    }
})

Vue.use(Router)

export default new Router({
    routes,
    base: process.env.BASE_URL,
    mode: 'history'
})