// Set Global URLs For Production and Testing Based on Release
export default {
    ADMIN_API: "https://adminapis.digicollect.com",
    // ADMIN_API: "https://testadminapis.digicollect.com",
    AUTH_URL: "https://admin.digicollect.com",
    // AUTH_URL: "https://testadmin.digicollect.com",
    HRMS_URL: "https://hrms.digicollect.com",
    // HRMS_URL: "https://testhrms.digicollect.com",
    HRMS_API: "https://hrmsapis.digicollect.com",
    // HRMS_API: "https://testhrmsapis.digicollect.com",
    AUTH_SERVICE: "https://adminapis.digicollect.com",
    // AUTH_SERVICE: "https://testadminapis.digicollect.com",
    POS_API: "https://posapismaster.digicollect.com",
    // POS_API: "https://testposapis.digicollect.com",
    SCM_API: "https://scmapis.digicollect.com",
    // SCM_API: "https://testscmapis.digicollect.com/manufacturing",
    CRM_API: "https://crmapis.digicollect.com",
    // CRM_API: "https://testcrmapis.digicollect.com",
    CRM_SALES_API: "https://crmsalesapis.digicollect.com",
    // CRM_SALES_API: "https://testcrmsalesapis.digicollect.com",
    SCM_COMMON_API: "https://scmapis.digicollect.com/common",
    // SCM_COMMON_API: "https://testscmapis.digicollect.com/common"

    // ADMIN_API: "https://adminapis.digicollect.com",
    // AUTH_URL: "https://admin.digicollect.com",
    // HRMS_URL: "https://hrms.digicollect.com",
    // // HRMS_URL: "https://testhrms.digicollect.com",
    // HRMS_API: "https://hrmsapis.digicollect.com",
    // // HRMS_API: "https://testhrmsapis.digicollect.com",
    // AUTH_SERVICE: "https://adminapis.digicollect.com",
    // // AUTH_SERVICE: "https://testadminapis.digicollect.com",
    // POS_API: "https://posapismaster.digicollect.com",
    // // POS_API: "https://testposapis.digicollect.com",
    // SCM_API: "https://scmapis.digicollect.com",
    // // SCM_API: "https://testscmapis.digicollect.com/manufacturing",
    // CRM_API: "https://crmapis.digicollect.com",
    // // CRM_API: "https://testcrmapis.digicollect.com",
    // CRM_SALES_API: "https://crmsalesapis.digicollect.com",
    // // CRM_SALES_API: "https://testcrmsalesapis.digicollect.com",
    // SCM_COMMON_API: "https://scmapis.digicollect.com/common",
};
